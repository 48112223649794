import { apiPostAuth } from '@/utils/api'
import { API_INJECT_STAMP_MANAGEMENT } from '@/utils/api-url'

import {
  notificationDanger
} from '@/utils/notification'
import router from '../../../router'

const state = () => {
  return {
    data_modal_add_edit: {},
    data_bulk_stamp: [],
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_BULK_STAMP(state, payload) {
    state.data_bulk_stamp = payload
  }
}

const actions = {
  async uploadBulkDigitalStamp (context, payload) {
    try {
      const response = await apiPostAuth(API_INJECT_STAMP_MANAGEMENT.UPLOAD_BULK_INJECT_STAMP_MANAGEMENT, payload)
      if (response.data.data !== null) {
        context.commit('SET_BULK_STAMP', response.data.data.data)
      } else {
        context.commit('SET_BULK_STAMP', [])
      }
    } catch (error) {
      router.push("/admin/heimdal/digital-stamp-inject")
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        route: 'DigitalStickerInject', 
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async confirmBulkDigitalStamp (context, payload) {
    try {
      const response = await apiPostAuth(API_INJECT_STAMP_MANAGEMENT.CONFIRM_BULK_INJECT_STAMP_MANAGEMENT, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_bulk_stamp (state){
    return state.data_bulk_stamp
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
